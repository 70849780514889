<template>
  <div id="FamilyIndex">
    <!-- 查询 -->
    <van-dropdown-menu active-color="#23AB72">
      <van-dropdown-item v-model="query.year" :options="options" @change="changeYear" />
    </van-dropdown-menu>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{row.year +'年'}}</span>家庭情况调查</span>
          </template>
          <template #label>
            <div class="custom-centent  hidden-text95">
              <svg-icon icon-class="date" size="15" /> 时间: {{parseTime(row.createTime, "{y}-{m}-{d}")}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="unit" size="15" /> 等级: {{(row.rankName === '01' || row.rankName === '02' || row.rankName === '03') ? row.rankName:'待认定'}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="unit" size="15" /> 评议: {{(row.reviewName === '01' || row.reviewName === '02') ? row.reviewName:'待评议'}}
            </div>
          </template>
          <template #right-icon>
            <span v-if="row.spzt ==='0'" class="audit info-color b-color-info">待提交</span>
            <span v-else-if="statustag(row.spzt,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-else-if="statustag(row.spzt,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.spzt,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.spzt,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <span class="table-detail-btn detail-pos" @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <span v-if="statustag(row.spzt,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
          </template>
          <template #right-icon>
            <van-button class="table-del-btn" :disabled="stuDisableBtn(row.spzt,'del')" @click="toDel(row)" :loading="row.delLoading" plain round size="small" type="warning">删除</van-button>
            <van-button class="table-edit-btn" :disabled="stuDisableBtn(row.spzt,'edit')" @click="toEdit(row)" plain round size="small" type="primary">编辑</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <!-- 提交 -->
    <div class="submit-button shadow-style">
      <van-button round block type="primary" @click="doAdd">新 增</van-button>
    </div>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { del } from "@/api/modules/student/pksxx";

export default {
  name: "FamilyIndex",
  mixins: [initData],
  dicts: ["xj_shzt", "xj_nj"],
  data() {
    return {
      showYearPicker: false,
      defaultIndex: 1,
      searchTetx: "",
      options: [],
    };
  },
  created() { },
  watch: {
    "dict.xj_nj": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0) {
          this.options = this.convertData(newval);
        }
      },
    },
  },
  methods: {
    beforeInit() {
      this.url = "/api/jz/pksxxStu";
      this.sort = ["year,desc"];
      return true;
    },
    onClickLeft() { },
    doAdd() {
      this.$router.push({ path: "/xs/a03/form" });
    },
    toDetail(data) {
      this.$router.push({
        path: "/xs/a03/detail",
        query: data,
      });
    },
    // 编辑
    toEdit(data) {
      this.$router.push({
        path: "/xs/a03/form",
        query: { id: data.id },
      });
    },
    // 检索
    onSearch() {
      console.log("2321321");
    },
    // 删除操作
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          del([data.id])
            .then((res) => {
              this.$notify({
                type: "success",
                message: "操作成功",
                duration: 2000,
              });
              this.init();
              this.$set(data, "delLoading", false);
            })
            .catch((e) => {
              this.$set(data, "delLoading", false);
            });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    onConfirm(value) {
      this.showYearPicker = false;
      this.query.year = value.label;
      this.toQuery();
    },
    convertData(dict) {
      let options = [];
      dict.forEach((item) => {
        let data = { text: item.label + "年", value: item.value };
        options.push(data);
      });
      if (options) {
        this.query.year = new Date().getFullYear().toString();
      }
      return options;
    },
    changeYear(val) {
      this.$nextTick((e) => {
        this.query.year = val;
      });
      this.toQuery();
    },
    cleanSearch() {
      this.searchTetx = "";
      this.query = {};
      this.toQuery();
    },
    openShowHandler() {
      // 默认选中数据
      this.dict.xj_nj.forEach((item, index) => {
        if (this.query.year) {
          if (this.query.year === item.value) {
            this.defaultIndex = index;
          }
        } else if (new Date().getFullYear().toString() === item.value) {
          this.defaultIndex = index;
        }
      });
      this.showYearPicker = true;
    },
    stuDisableBtn(spzt, type) {
      let status = parseInt(spzt);
      if ([9].indexOf(status) > -1) {
        return true;
      }
      if ([101, 201, 301, 2, 3].indexOf(status) > -1) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang='scss' scoped>
@import '~@/assets/styles/student/family.scss';
.van-list {
  margin-bottom: 54px;
}
.van-cell__title {
  font-size: 16px;
  text-align: left;
  min-width: 70%;
}
::v-deep .van-dropdown-menu__bar {
  height: 44px;
  box-shadow: 0 0px 0px rgb(100 101 102 / 12%);
}
.detail-pos {
  position: absolute;
  top: 50%;
  right: 15px;
}
#opt-style {
  .van-cell__title {
    min-width: 50%;
    font-size: 14px;
    line-height: 32px;
  }
}
</style>
